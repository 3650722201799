import * as React from "react"
import DefaultLayout from "../layouts/default";
import SEO from "../components/seo";
import Obfuscate from "react-obfuscate"

import kontaktGrafika from "../images/kontakt_grafika.svg"
import kontaktTekst from "../images/kontakt_tekst.svg"


const KontaktPage = () => {
    return (
        <DefaultLayout>
            <SEO title="kontakt" />
            <article className="prose">
                <img className="h-80 mx-auto" src={kontaktGrafika} alt="kontakt"></img>
                <img className="h-12 mx-auto" src={kontaktTekst} alt="kontakt"></img>
                <h3>Zespół Projektowy</h3>
                <p>
                    <span>Magdalena Urban - kierownik projektu, Fundacja Ludzi Otwartej Wyobraźni FLOW </span>
                    <Obfuscate email="magdalenaurban@fundacjaflow.edu.pl"></Obfuscate>
                </p>
                <p>
                    <span>Grzegorz Babicki - nauczyciel geografii w No Bell Szkole Podstawowej Montessori </span>
                    <Obfuscate email="grzegorz@aktywnaedukacja.pl"></Obfuscate>
                </p>
                <p>
                    <span>Jan Ignacy Czempiński - nauczyciel geografii w No Bell Liceum Ogólnokształcącym </span>
                    <Obfuscate email="janignacy@aktywnaedukacja.pl"></Obfuscate>
                </p>
            </article>
        </DefaultLayout>
    )
}

export default KontaktPage